<template>
  <div>
    <a-row :gutter="[20, 0]">
      <a-col :span="24">
        <h3 v-if="serviceID == 1" class="title">
          <a-row>
            <a-col :span="8"> SERVIÇOS </a-col>
            <a-col class="a-right" :span="8" :offset="8">
              <a-button
                v-if="add && totalRows < 6"
                size="small"
                @click="addRow"
                type="link"
              >
                Adicionar
              </a-button>
            </a-col>
          </a-row>
        </h3>
      </a-col>

      <a-col :span="6">
        <a-form-item>
          <span class="travel-input">
            <label
              :class="
                form.getFieldValue(`service_${serviceID}_type`) ? 'filled' : ''
              "
              >Tipo de serviço</label
            >
            <a-select
              class="travel-input"
              placeholder="Tipo de serviço"
              v-decorator="[
                `service_${serviceID}_type`,
                {
                  rules: [
                    {
                      required: this.dynamicRequiredField(),
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
              style="width: 100%; height: 30px"
            >
              <a-select-option value="Aluguel de Carro">
                Aluguel de Carro
              </a-select-option>
              <a-select-option value="Circuito"> Circuito </a-select-option>
              <a-select-option value="Ingresso"> Ingresso </a-select-option>
              <a-select-option value="Passeio"> Passeio </a-select-option>
              <a-select-option value="Seguro Viagem">
                Seguro Viagem
              </a-select-option>
              <a-select-option value="Transfer In">
                Transfer In
              </a-select-option>
              <a-select-option value="Transfer In/Out">
                Transfer In/Out
              </a-select-option>
            </a-select>
          </span>
        </a-form-item>
      </a-col>

      <a-col :span="7">
        <a-form-item>
          <label
            :class="
              this.form.getFieldValue(`service_${serviceID}_name`)
                ? 'filled'
                : ''
            "
            >Descrição/Nome do serviço</label
          >
          <a-input
            class="travel-input"
            placeholder="Descrição/Nome do serviço"
            v-decorator="[
              'service_' + serviceID + '_name',
              {
                rules: [
                  {
                    required: this.dynamicRequiredField(),
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="5">
        <a-form-item>
          <label
            :class="
              this.form.getFieldValue(`service_${serviceID}_locator`)
                ? 'filled'
                : ''
            "
            >Localizador</label
          >
          <a-input
            class="travel-input"
            placeholder="Localizador (opcional)"
            v-decorator="['service_' + serviceID + '_locator']"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="4">
        <a-form-item>
          <label
            :class="
              this.form.getFieldValue(`service_${serviceID}_total_price`)
                ? 'filled'
                : ''
            "
            >Valor total</label
          >
          <a-input
            class="travel-input"
            placeholder="Valor total"
            @blur="emitValueUpdate"
            v-currency
            v-decorator="[
              'service_' + serviceID + '_total_price',
              {
                rules: [
                  {
                    required: dynamicRequiredField(),
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="2" style="text-align: center">
        <a-button
          v-if="id == totalRows && totalRows > 1"
          size="small"
          @click="removeRow"
          type="danger"
          shape="circle"
          icon="delete"
        >
        </a-button>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: "ServiceForm",
  props: {
    id: Number,
    add: Boolean,
    totalRows: Number,
    serviceRows: Array,
    contract: Object,
    tempContract: Object,
    service: Object,
    form: Object,
  },
  data() {
    return {
      serviceID: this.serviceRows[this.id - 1].id,
      serviceIndex: this.id - 1,
    };
  },
  beforeMount() {
    setTimeout(() => {
      this.form.setFieldsValue({
        [`service_${this.serviceID}_type`]:
          this.contract[`service_${this.serviceID}_type`],
        [`service_${this.serviceID}_name`]:
          this.contract[`service_${this.serviceID}_name`],
        [`service_${this.serviceID}_locator`]:
          this.contract[`service_${this.serviceID}_locator`],
        [`service_${this.serviceID}_total_price`]:
          this.contract[`service_${this.serviceID}_total_price`],
      });
    }, 20);
  },
  methods: {
    removeRow() {
      this.$emit("removeServiceRows", this.id);
    },
    addRow() {
      this.$emit("updateServiceRows", this.serviceRows.length);
    },
    emitValueUpdate() {
      this.$emit("emitValueUpdate");
    },
    dynamicRequiredField() {
      let flag = this.form.getFieldValue("contracted_services");

      if (flag != undefined) {
        flag = this.form
          .getFieldValue("contracted_services")
          .includes("service");
      } else {
        flag = false;
      }

      return flag;
    },
  },
};
</script>
