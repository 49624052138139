var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-row',{attrs:{"gutter":[20, 0]}},[_c('a-col',{attrs:{"span":24}},[(_vm.serviceID == 1)?_c('h3',{staticClass:"title"},[_c('a-row',[_c('a-col',{attrs:{"span":8}},[_vm._v(" SERVIÇOS ")]),_vm._v(" "),_c('a-col',{staticClass:"a-right",attrs:{"span":8,"offset":8}},[(_vm.add && _vm.totalRows < 6)?_c('a-button',{attrs:{"size":"small","type":"link"},on:{"click":_vm.addRow}},[_vm._v("\n              Adicionar\n            ")]):_vm._e()],1)],1)],1):_vm._e()]),_vm._v(" "),_c('a-col',{attrs:{"span":6}},[_c('a-form-item',[_c('span',{staticClass:"travel-input"},[_c('label',{class:_vm.form.getFieldValue(`service_${_vm.serviceID}_type`) ? 'filled' : ''},[_vm._v("Tipo de serviço")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              `service_${_vm.serviceID}_type`,
              {
                rules: [
                  {
                    required: this.dynamicRequiredField(),
                    message: 'Obrigatório',
                  },
                ],
              },
            ]),expression:"[\n              `service_${serviceID}_type`,\n              {\n                rules: [\n                  {\n                    required: this.dynamicRequiredField(),\n                    message: 'Obrigatório',\n                  },\n                ],\n              },\n            ]"}],staticClass:"travel-input",staticStyle:{"width":"100%","height":"30px"},attrs:{"placeholder":"Tipo de serviço"}},[_c('a-select-option',{attrs:{"value":"Aluguel de Carro"}},[_vm._v("\n              Aluguel de Carro\n            ")]),_vm._v(" "),_c('a-select-option',{attrs:{"value":"Circuito"}},[_vm._v(" Circuito ")]),_vm._v(" "),_c('a-select-option',{attrs:{"value":"Ingresso"}},[_vm._v(" Ingresso ")]),_vm._v(" "),_c('a-select-option',{attrs:{"value":"Passeio"}},[_vm._v(" Passeio ")]),_vm._v(" "),_c('a-select-option',{attrs:{"value":"Seguro Viagem"}},[_vm._v("\n              Seguro Viagem\n            ")]),_vm._v(" "),_c('a-select-option',{attrs:{"value":"Transfer In"}},[_vm._v("\n              Transfer In\n            ")]),_vm._v(" "),_c('a-select-option',{attrs:{"value":"Transfer In/Out"}},[_vm._v("\n              Transfer In/Out\n            ")])],1)],1)])],1),_vm._v(" "),_c('a-col',{attrs:{"span":7}},[_c('a-form-item',[_c('label',{class:this.form.getFieldValue(`service_${_vm.serviceID}_name`)
              ? 'filled'
              : ''},[_vm._v("Descrição/Nome do serviço")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'service_' + _vm.serviceID + '_name',
            {
              rules: [
                {
                  required: this.dynamicRequiredField(),
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            'service_' + serviceID + '_name',\n            {\n              rules: [\n                {\n                  required: this.dynamicRequiredField(),\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":"Descrição/Nome do serviço"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_c('a-form-item',[_c('label',{class:this.form.getFieldValue(`service_${_vm.serviceID}_locator`)
              ? 'filled'
              : ''},[_vm._v("Localizador")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['service_' + _vm.serviceID + '_locator']),expression:"['service_' + serviceID + '_locator']"}],staticClass:"travel-input",attrs:{"placeholder":"Localizador (opcional)"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":4}},[_c('a-form-item',[_c('label',{class:this.form.getFieldValue(`service_${_vm.serviceID}_total_price`)
              ? 'filled'
              : ''},[_vm._v("Valor total")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
            'service_' + _vm.serviceID + '_total_price',
            {
              rules: [
                {
                  required: _vm.dynamicRequiredField(),
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            'service_' + serviceID + '_total_price',\n            {\n              rules: [\n                {\n                  required: dynamicRequiredField(),\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":"Valor total"},on:{"blur":_vm.emitValueUpdate}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{staticStyle:{"text-align":"center"},attrs:{"span":2}},[(_vm.id == _vm.totalRows && _vm.totalRows > 1)?_c('a-button',{attrs:{"size":"small","type":"danger","shape":"circle","icon":"delete"},on:{"click":_vm.removeRow}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }